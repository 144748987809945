import type { LucideIcon } from 'lucide-react';
import { Info } from 'lucide-react';
import type { ReactNode } from 'react';

import { cn } from '~/utils/css';

type AlertColour = 'yellow' | 'brand';
type AlertStyle = {
  background: string;
  icon: string;
  title: string;
  description: string;
};

const colourStyles: Record<AlertColour, AlertStyle> = {
  brand: {
    background: 'bg-brand-50',
    icon: 'text-brand-700',
    title: 'text-brand-800',
    description: 'text-brand-700',
  },
  yellow: {
    background: 'bg-yellow-50',
    icon: 'text-yellow-400',
    title: 'text-yellow-800',
    description: 'text-yellow-700',
  },
};

type AlertProps = {
  icon: LucideIcon;
  colour: AlertColour;
  title: string;
  children:
    | ReactNode
    | undefined
    | (({ styles }: { styles: AlertStyle }) => ReactNode);
};

export function Alert(props: AlertProps) {
  const styles = colourStyles[props.colour];

  const Icon = props.icon;

  return (
    <div className={cn('rounded-md p-4', styles.background)}>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <Icon className={cn('h-5 w-5', styles.icon)} aria-hidden='true' />
        </div>

        <div className='ml-3'>
          <h3 className={cn('text-sm font-medium', styles.title)}>
            {props.title}
          </h3>

          {typeof props.children === 'function'
            ? props.children({ styles })
            : props.children}
        </div>
      </div>
    </div>
  );
}

export function ChristmasClosureAlert() {
  return (
    <div className='relative z-10 mx-auto my-2 max-w-7xl px-2 sm:my-4 sm:px-4 md:px-6 lg:my-6'>
      <Alert colour='brand' icon={Info} title='Christmas closure'>
        {({ styles }) => (
          <>
            <div className={cn('mt-2 text-sm', styles.description)}>
              <p>
                Due to our Christmas closure period, our ordering system will
                re-open on the 31st of December, 2023.
              </p>
            </div>

            <div className={cn('mt-2 text-sm', styles.description)}>
              <p>
                Orders placed on or after December 31st, 2023 (and before the
                weekly cutoff) will be available for delivery or pickup on the
                7th/8th of January, 2024.
              </p>
            </div>
          </>
        )}
      </Alert>
    </div>
  );
}

export function VerifyEmailAlert() {
  return (
    <div className='relative z-10 mb-2 sm:mb-4 lg:mb-6'>
      <Alert colour='yellow' icon={Info} title='Verify your account'>
        {({ styles }) => (
          <>
            <div className={cn('mt-2 text-sm', styles.description)}>
              <p>
                Due to our Christmas closure period, our ordering system will
                re-open on the 31st of December, 2023.
              </p>
            </div>

            <div className={cn('mt-2 text-sm', styles.description)}>
              <p>
                Orders placed on or after December 31st, 2023 (and before the
                weekly cutoff) will be available for delivery or pickup on the
                7th/8th of January, 2024.
              </p>
            </div>
          </>
        )}
      </Alert>
    </div>
  );
}
